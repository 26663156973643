.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-drawer-body{
  margin:0 0 0 0 !important;
  padding:0 0 0 0 !important;
}

html,body {
  max-width:768px !important;
  margin-left:auto;
  margin-right:auto;
}

.footer-links
{
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-size: 100%;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.footer-links a
{
  color:#ffffff;
}


h1{
  font-family: 'Lemon', cursive;
}

.divider{
  font-family: 'Lemon', cursive;
}

.appHeader{
  font-family: 'Lemon', cursive;
  color: white;
  font-size: 200%;
}

.storeName{
  font-family: 'Lemon', cursive;
}

.longPara{
  font-family: 'EB Garamond', serif;
  font-size:100%;
}

.profileName{
  font-family: 'Boogaloo', sans-serif;
  font-size:150%;
}

.profileAge{
  font-family: 'EB Garamond', serif;
  font-size:70%;
}

.ant-drawer-content-wrapper{
  width:250px !important;
}